import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/vuex/store'
import axios from 'axios'
import qs from 'qs'
import vClickOutside from 'v-click-outside'
import Global from '@/mixins/global'
import VueGtm from '@gtm-support/vue2-gtm'
import VueRouter from 'vue-router'
import dayjs from 'dayjs'

//Plugins
import vuetify from '@/plugins/vuetify'
import '@/plugins/Dayjs'

Vue.config.productionTip = false

// Global Components
import SvgImg from '@/components/SvgImg'
Vue.component('svg-img', SvgImg)
import UserIcon from '@/components/UserIcon'
Vue.component('user-icon', UserIcon)

// Setting up global mixins across app: https://freek.dev/823-using-global-mixins-in-vuejs
Vue.mixin(Global)

// Vue use
Vue.use(vClickOutside)

// Setup Google Analytics
//const router = new VueRouter({ routes, mode, linkActiveClass });
Vue.use(VueGtm, {
  id: 'GTM-MWJ6DR',
  vueRouter: router
})

// Handle navigation duplication for router push (Globally)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((error) => {})
}

// Add vanilla javascript libraries globally to vue
Object.defineProperty(Vue.prototype, '$dayjs', { value: dayjs })

new Vue({
  router,
  store,
  axios,
  qs,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')

// Vue Filters
Vue.filter('date', function (value) {
  return dayjs(value).format('M/D/YY')
})
Vue.filter('year', function (value) {
  return dayjs(value).format('YYYY')
})
