<template>
  <v-expansion-panels :value="panel" multiple>
    <v-expansion-panel
      v-for="advisor in advisors"
      :key="advisor.cst_key"
      :value-comparator="simpleMatch"
      :prepend-icon="advisor.action"
    >
      <v-container class="advisor-card" v-if="advisor.cst_key">
        <v-row>
          <v-col cols="0" sm="3" md="2" class="big-screen trim-bottom-padding">
            <v-badge
              :content="galleryCount(advisor)"
              bottom
              left
              v-if="galleryCount(advisor) == 1000"
            >
              <v-img
                class="profile-photo"
                width="135"
                height="135"
                :src="`/napi/advisor-profile-image?cst_no=${advisor.cst_no}`"
              ></v-img>
            </v-badge>
            <v-img
              v-else
              class="profile-photo"
              width="135"
              height="135"
              :src="`/napi/advisor-profile-image?cst_no=${advisor.cst_no}`"
            ></v-img>
            <!-- REMOVE - this is for testing -->
            <!-- <div> -->
            <!-- Zip Code: {{ advisor.postal_code }} -->
            <!-- </div> -->
            <div class="member-details">
              <p v-if="advisor.membership_type && isSeeking(advisor)">
                {{
                  advisor.membership_type != 'Basic'
                    ? advisor.membership_type
                    : ''
                }}
                Member
              </p>
              <p
                v-if="advisor.cima_status == 'Certified'"
                class="advisor-credential"
              >
                CIMA Certified
                <span v-if="advisor.cima_date">
                  Since {{ advisor.cima_date | year }}
                </span>
              </p>
              <p
                v-if="advisor.cpwa_status == 'Certified'"
                class="advisor-credential"
              >
                CPWA Certified
                <span v-if="advisor.cpwa_date">
                  Since {{ advisor.cpwa_date | year }}
                </span>
              </p>
              <p
                v-if="advisor.rma_status == 'Certified'"
                class="advisor-credential"
              >
                RMA Certified
                <span v-if="advisor.rma_date">
                  Since {{ advisor.rma_date | year }}
                </span>
              </p>
              
              <template v-if="isSeeking(advisor)">
                <h5 v-if="advisor.credentials">
                  Other Designations:
                  <span>(Self-Reported)</span>
                </h5>
                <p
                  class="advisor-credential"
                  v-for="credential in advisor.credentials.split(',')"
                  :key="credential"
                >
                  {{ credential }}
                </p>

                <div v-if="advisor.assets_under_advisement" class="small-screen">
                  <h5>Assets under management</h5>
                  <p>
                    {{
                      data.assetsUnderAdvisement[advisor.assets_under_advisement]
                        .title
                    }}
                  </p>
                </div>
              </template>

              <h5 class="small-screen">Disciplinary history</h5>
              <p class="small-screen">
                {{ advisor.disciplinary_history || 'None' }}
              </p>
              <h5 v-if="$vuetify.breakpoint.xsOnly">
                <a href="https://brokercheck.finra.org/" target="_blank">
                  FINRA Broker Check
                </a>
                <v-icon x-small color="#243746">mdi-open-in-new</v-icon>
              </h5>
            </div>
          </v-col>
          <v-col cols="12" sm="9" md="10">
            <v-container class="advisor-container">
              <v-row class="advisor-titlebar">
                <v-col cols="4" class="small-screen">
                  <v-img
                    class="profile-photo"
                    width="135"
                    height="135"
                    :src="`/napi/advisor-profile-image?cst_no=${advisor.cst_no}`"
                  ></v-img>
                </v-col>
                <v-col cols="8" lg="9" class="name-fields">
                  <h3 class="advisor-name">
                    <router-link :to="`/advisor/detail/${advisor.cst_no}`">
                      {{ preferredName(advisor) + ' ' + advisor.last_name }}<span
                        class="big-screen advisor-titles"
                        v-html="advisorHeader(advisor)"
                      ></span>
                    </router-link>

                    <template v-if="appState.loggedIn">
                      <v-btn-toggle
                        borderless
                        multiple
                        v-model="user.favorite_advisors"
                        @change="$store.dispatch('updateInvestorProfile')"
                        :value-comparator="simpleMatch"
                        class="favorite-button"
                        dense
                      >
                        <v-btn :value="advisor.cst_key" icon color="#f25d07">
                          <v-icon
                            v-if="
                              user.favorite_advisors.includes(advisor.cst_key)
                            "
                          >
                            mdi-heart
                          </v-icon>
                          <v-icon
                            v-if="
                              !user.favorite_advisors.includes(advisor.cst_key)
                            "
                          >
                            mdi-heart-outline
                          </v-icon>
                        </v-btn>
                      </v-btn-toggle>
                    </template>
                  </h3>

                  <div class="small-screen-location" v-if="isSeeking(advisor)">
                    {{ advisor.city }},
                    {{ advisor.state }}
                  </div>
                  <p
                    class="small-screen advisor-titles"
                    v-html="advisorHeader(advisor)"
                  ></p>
                </v-col>
                <v-col
                  cols="4"
                  lg="3"
                  class="more-info-container"
                  v-if="!advisor.detail_only && $vuetify.breakpoint.smAndUp && isSeeking(advisor)"
                >
                  <v-expansion-panel-header
                    :disable-icon-rotate="false"
                    class="big-screen more-info"
                  >
                    <h6 class="more">More Info</h6>
                    <h6 class="less">Less Info</h6>
                    <template v-slot:actions>
                      <v-icon color="#F8D543" light>mdi-plus-circle</v-icon>
                    </template>
                  </v-expansion-panel-header>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="member-details small-screen">
                  <p
                    v-if="
                      advisor.membership_type &&
                      advisor.membership_type != 'Basic' &&
                      isSeeking(advisor)
                    "
                  >
                    {{ advisor.membership_type }} Member
                  </p>
                  <p
                    v-if="advisor.cima_status == 'Certified'"
                    class="advisor-credential"
                  >
                    CIMA Certified
                    <span v-if="advisor.cima_date">
                      Since {{ advisor.cima_date | date }}
                    </span>
                  </p>
                  <p
                    v-if="advisor.cpwa_status == 'Certified'"
                    class="advisor-credential"
                  >
                    CPWA Certified
                    <span v-if="advisor.cpwa_date">
                      Since {{ advisor.cpwa_date | date }}
                    </span>
                  </p>
                  <p
                    v-if="advisor.rma_status == 'Certified'"
                    class="advisor-credential"
                  >
                    RMA Certified
                    <span v-if="advisor.rma_date">
                      Since {{ advisor.rma_date | date }}
                    </span>
                  </p>

                  <template v-if="isSeeking(advisor)">
                    <h5>
                      Other Designations:
                      <span>(Self-Reported)</span>
                    </h5>
                    <p
                      class="advisor-credential"
                      v-for="credential in advisor.credentials.split(',')"
                      :key="credential"
                    >
                      {{ credential }}
                    </p>
                  </template>
                  <template v-if="advisor.assets_under_advisement && isSeeking(advisor)">
                    <h5>Assets under management</h5>
                    <p>
                      {{
                        data.assetsUnderAdvisement[
                          advisor.assets_under_advisement
                        ].title
                      }}
                    </p>
                  </template>

                  <h5>Disciplinary history</h5>
                  <p>{{ advisor.disciplinary_history || 'None' }}</p>
                  <h5>
                    <a href="https://brokercheck.finra.org/" target="_blank">
                      FINRA Broker Check
                    </a>
                    <v-icon x-small color="#243746">mdi-open-in-new</v-icon>
                  </h5>
                </v-col>
                <v-col
                  sm="5"
                  md="3"
                  class="match-details"
                  v-if="
                    ((!advisor.detail_only && !isSearch) ||
                    isSearch == 'favorites') && isSeeking(advisor)
                  "
                >
                  <h5>How You Match</h5>

                  <template v-if="data.settings.debugMode">
                    <h6>
                      <template v-if="data.settings.debugMode">
                        {{ advisor.scores.investableWealth * 100 }}%:
                      </template>
                      Investable Wealth
                    </h6>
                    <div class="match-detail-wrapper">
                      <div
                        class="match-detail-bar"
                        :style="`width: ${
                          advisor.scores.investableWealth * 100
                        }%`"
                      ></div>
                    </div>
                  </template>

                  <template v-if="hasSelected('lifeStage')">
                    <h6>
                      <template v-if="data.settings.debugMode">
                        {{ advisor.scores.lifeStage * 100 }}%:
                      </template>
                      Life Stage
                    </h6>
                    <div class="match-detail-wrapper">
                      <div
                        class="match-detail-bar"
                        :style="`width: ${advisor.scores.lifeStage * 100}%`"
                      ></div>
                    </div>
                  </template>

                  <template v-if="hasSelected('investableWealth')">
                    <h6>
                      <template v-if="data.settings.debugMode">
                        {{ advisor.scores.investableWealth * 100 }}%:
                      </template>
                      Your Finances
                    </h6>
                    <div class="match-detail-wrapper">
                      <div
                        class="match-detail-bar"
                        :style="`width: ${
                          advisor.scores.investableWealth * 100
                        }%`"
                      ></div>
                    </div>
                  </template>

                  <template v-if="data.settings.debugMode">
                    <h6>
                      {{
                        (advisor.scores.preferredAttributes / nearbyScore) *
                        100
                      }}%: Your Preferences (No Nearby Score)
                    </h6>
                    <div class="match-detail-wrapper">
                      <div
                        class="match-detail-bar"
                        :style="`width: ${
                          (advisor.scores.preferredAttributes / nearbyScore) *
                          100
                        }%`"
                      ></div>
                    </div>
                    <h6>
                      {{ visualNearbyScore(advisor) }}%: Your Nearby Score
                    </h6>
                    <div class="match-detail-wrapper">
                      <div
                        class="match-detail-bar"
                        :style="`width: ${visualNearbyScore(advisor)}%`"
                      ></div>
                    </div>
                  </template>

                  <template
                    v-if="
                      hasSelected('preferredAttributes') ||
                      hasSelected('nearby')
                    "
                  >
                    <h6>
                      <template v-if="data.settings.debugMode">
                        {{ visualPreferencesScore(advisor) }}%:
                      </template>
                      Your Preferences
                    </h6>

                    <div class="match-detail-wrapper">
                      <div
                        class="match-detail-bar"
                        :style="`width: ${visualPreferencesScore(advisor)}%`"
                      ></div>
                    </div>
                  </template>

                  <template v-if="hasSelected('lifeEvents')">
                    <h6>
                      <template v-if="data.settings.debugMode">
                        {{ advisor.scores.lifeEvents * 100 }}%:
                      </template>
                      Your Situation
                    </h6>
                    <div class="match-detail-wrapper">
                      <div
                        class="match-detail-bar"
                        :style="`width: ${advisor.scores.lifeEvents * 100}%`"
                      ></div>
                    </div>
                  </template>

                  <template v-if="hasSelected('importantFactors')">
                    <h6>
                      <template v-if="data.settings.debugMode">
                        {{ advisor.scores.importantFactors * 100 }}%:
                      </template>
                      Your Priorities
                    </h6>
                    <div class="match-detail-wrapper">
                      <div
                        class="match-detail-bar"
                        :style="`width: ${
                          advisor.scores.importantFactors * 100
                        }%`"
                      ></div>
                    </div>
                  </template>
                  <template v-if="data.settings.debugMode">
                    <h6>
                      <template v-if="data.settings.debugMode">
                        {{ advisor.scores.total * 100 }}%:
                      </template>
                      Total
                    </h6>
                    <div class="match-detail-wrapper">
                      <div
                        class="match-detail-bar"
                        :style="`width: ${advisor.scores.total * 100}%`"
                      ></div>
                    </div>
                  </template>
                </v-col>

                <v-col
                  cols="12"
                  :sm="advisor.detail_only ? '9' : '1'"
                  :md="advisor.detail_only ? '10' : '6'"
                  class="advisor-intro"
                  v-if="isSeeking(advisor)"
                >
                  <p class="advisor-firm-location">
                    <template v-if="advisor.org_name">
                      <span>{{ advisor.org_name }}</span>
                      ,
                    </template>
                    <span class="big-screen">
                      {{ advisor.city }},
                      {{ advisor.state }}
                    </span>
                  </p>

                  <template v-if="advisor.practice_description">

                    <p class="advisor-intro" v-if="advisor.practice_description">
                      {{ advisor.practice_description }}
                    </p>
                    <div
                      class="aum-discipline big-screen"
                      v-if="!advisor.practice_description"
                    >
                      <template v-if="advisor.assets_under_advisement">
                        <h5>Assets under management</h5>
                        <p>
                          {{
                            data.assetsUnderAdvisement[
                              advisor.assets_under_advisement
                            ].title
                          }}
                        </p>
                      </template>

                      <h5>Disciplinary history</h5>
                      <p>{{ advisor.disciplinary_history || 'None' }}</p>
                      <h5>
                        <a href="https://brokercheck.finra.org/" target="_blank">
                          FINRA Broker Check
                        </a>
                        <v-icon x-small color="#243746">mdi-open-in-new</v-icon>
                      </h5>
                    </div>
                  </template>
                </v-col>

                <v-col cols="0" md="3" class="aum-discipline big-screen">
                  <template v-if="advisor.assets_under_advisement && isSeeking(advisor)">
                    <h5>Assets under management</h5>
                    <p>
                      {{
                        data.assetsUnderAdvisement[
                          advisor.assets_under_advisement
                        ].title
                      }}
                    </p>
                  </template>

                  <h5>Disciplinary history</h5>
                  <p>{{ advisor.disciplinary_history || 'None' }}</p>
                  <h5>
                    <a href="https://brokercheck.finra.org/" target="_blank">
                      FINRA Broker Check
                    </a>
                    <v-icon x-small color="#243746">mdi-open-in-new</v-icon>
                  </h5>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>

        <v-row v-if="isSeeking(advisor)">
          <v-col class="trim-top-padding">
            <v-expansion-panel-content>
              <v-container>
                <v-row>
                  <v-col cols="0" sm="3" md="2" class="big-screen"></v-col>
                  <v-col cols="12" sm="9" md="10" class="advisor-expansion">
                    <v-container>
                      <v-row v-if="!advisor.detail_only && !isSearch">
                        <v-col>
                          <v-divider></v-divider>
                        </v-col>
                      </v-row>
                      <v-row v-if="!advisor.detail_only && !isSearch">
                        <v-col class="key-matches">
                          <h5 v-if="advisor.detail_only || isSearch">
                            Advisor Highlights
                          </h5>
                          <h5 v-else>
                            {{ advisor.matchIds.length }} of
                            {{ calculated.matchFilter.length }} Key matches
                          </h5>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="key-matches">
                          <ul>
                            <template v-if="!advisor.detail_only && !isSearch">
                              <li v-if="matchingLifeStage(advisor)">
                                <v-img
                                  max-height="48"
                                  max-width="35"
                                  :title="aboutMe[0].definition"
                                  :src="getAttribIcon(aboutMe[0].slug)"
                                ></v-img>
                                {{ aboutMe[0].title }}
                              </li>
                              <li
                                v-if="matchingAttribute(advisor, nearbyObject)"
                              >
                                <v-img
                                  max-height="48"
                                  max-width="35"
                                  :title="nearbyObject.definition"
                                  :src="getAttribIcon(nearbyObject.slug)"
                                ></v-img>
                                {{ nearbyObject.title }}
                              </li>
                            </template>
                            <template  v-if="!advisor.detail_only && !isSearch">
                              <template v-for="attribute in data.preferredAttributes">
                                <li
                                  v-if="matchingAttribute(advisor, attribute)"
                                  :key="attribute.id"
                                >
                                  <v-img
                                    max-height="48"
                                    max-width="35"
                                    :title="attribute.definition"
                                    :src="getAttribIcon(attribute.slug)"
                                  ></v-img>
                                  {{ attribute.title }}
                                </li>
                              </template>
                            </template>
                            <template v-if="!advisor.detail_only && !isSearch">
                              <template v-for="event in data.lifeEvents">
                                <li
                                  v-if="matchingHighlight(advisor, event)"
                                  :key="event.id"
                                >
                                  <v-img
                                    max-height="48"
                                    max-width="35"
                                    :title="event.definition"
                                    :src="`/img/icons/icon-${event.slug}.svg`"
                                  ></v-img>
                                  {{ event.title }}
                                </li>
                              </template>
                            </template>
                            <template v-if="!advisor.detail_only && !isSearch">
                              <template v-for="factor in data.importantFactors">
                                <li
                                  v-if="matchingHighlight(advisor, factor)"
                                  :key="factor.id"
                                >
                                  <v-img
                                    max-height="48"
                                    max-width="35"
                                    :title="factor.definition"
                                    :src="`/img/icons/icon-${factor.slug}.svg`"
                                  ></v-img>
                                  {{ factor.title }}
                                </li>
                              </template>
                            </template>
                          </ul>
                        </v-col>
                      </v-row>

                      <template
                        v-if="advisor.provided_services || hasCert(advisor)"
                      >
                        <v-row>
                          <v-col>
                            <v-divider></v-divider>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            v-if="hasCert(advisor)"
                            class="all-services certified"
                            :class="{ rightBorder: advisor.provided_services }"
                            cols="3"
                          >
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <h5 class="tooltip" v-bind="attrs" v-on="on">
                                  Certified Services
                                </h5>
                              </template>
                              <span>
                                The Investments & Wealth Institute is the
                                certifying body for professionals who have
                                earned the Institute’s CIMA®, CPWA® and RMA®
                                certifications. InvestmentHelp.org, managed by
                                the Institute, is designed to provide the public
                                with a directory of individuals who have earned
                                the CIMA®, CPWA® and RMA® certifications.
                                InvestmentHelp.org listings provide information
                                that is self-reported by participants. The
                                Investments & Wealth Institute does NOT
                                independently verify such self-reported
                                information. Investors should do their own due
                                diligence before hiring a financial advisor.
                                Investments & Wealth Institute does not endorse
                                or recommend financial advisors. Information
                                provided through InvestmentHelp.org is for
                                informational purposes only and is not, in any
                                way, to be considered investment advice, nor
                                should it be construed as a recommendation of
                                any investment product(s), or endorsement(s) of
                                any financial advisor, member or certificant.
                                Before hiring a professional, regulators suggest
                                you search the professional’s regulatory record
                                on FINRA’s or the SEC’s website.
                              </span>
                            </v-tooltip>

                            <ul>
                              <li v-if="advisor.cima_status == 'Certified'">
                                <router-link
                                  to="/certification/certified-investment-management-analyst"
                                >
                                  Investment Management
                                </router-link>
                              </li>
                              <li v-if="advisor.cpwa_status == 'Certified'">
                                <router-link
                                  to="/certification/certified-private-wealth-advisor"
                                >
                                  Wealth Management
                                </router-link>
                              </li>
                              <li v-if="advisor.rma_status == 'Certified'">
                                <router-link
                                  to="/certification/retirement-management-advisor"
                                >
                                  Retirement Management
                                </router-link>
                              </li>
                            </ul>
                          </v-col>

                          <v-col
                            class="all-services"
                            v-if="advisor.provided_services"
                          >
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <h5 class="tooltip" v-bind="attrs" v-on="on">
                                  Self-Reported Services
                                </h5>
                              </template>
                              <span>
                                The Investments & Wealth Institute is the
                                certifying body for professionals who have
                                earned the Institute’s CIMA®, CPWA® and RMA®
                                certifications. InvestmentHelp.org, managed by
                                the Institute, is designed to provide the public
                                with a directory of individuals who have earned
                                the CIMA®, CPWA® and RMA® certifications.
                                InvestmentHelp.org listings provide information
                                that is self-reported by participants. The
                                Investments & Wealth Institute does NOT
                                independently verify such self-reported
                                information. Investors should do their own due
                                diligence before hiring a financial advisor.
                                Investments & Wealth Institute does not endorse
                                or recommend financial advisors. Information
                                provided through InvestmentHelp.org is for
                                informational purposes only and is not, in any
                                way, to be considered investment advice, nor
                                should it be construed as a recommendation of
                                any investment product(s), or endorsement(s) of
                                any financial advisor, member or certificant.
                                Before hiring a professional, regulators suggest
                                you search the professional’s regulatory record
                                on FINRA’s or the SEC’s website.
                              </span>
                            </v-tooltip>

                            <ul>
                              <template
                                v-for="service in csvToArray(
                                  advisor.provided_services
                                )"
                              >
                                <li :key="service" v-if="service != ''">
                                  {{ service }}
                                </li>
                              </template>
                            </ul>
                          </v-col>
                        </v-row>
                      </template>

                      <template v-if="isPremiumAdvisor(advisor)">
                        <template v-if="advisor.profile_intro">
                          <v-row>
                            <v-col><v-divider></v-divider></v-col>
                          </v-row>

                          <v-row class="more-about">
                            <v-col>
                              <h5>
                                More about {{ preferredName(advisor) }}
                                {{ toTitle(advisor.last_name) }}
                              </h5>
                            </v-col>
                          </v-row>

                          <v-row class="more-about">
                            <v-col>
                              <p>{{ advisor.profile_intro }}</p>
                            </v-col>
                          </v-row>
                        </template>

                        <template v-if="advisor.icons.length">
                          <v-row>
                            <v-col><v-divider></v-divider></v-col>
                          </v-row>

                          <!--NO ACCOLADES DATA IN API AT PRESENT <v-row class="accolades">
                            <v-col v-if="advisor.profile_intro">
                              <h5>
                                Other accolades and accomplishments
                                (self-reported)
                              </h5>
                            </v-col>
                            <v-col v-if="!advisor.profile_intro">
                              <h5>
                                More about {{ preferredName(advisor) }}
                                {{ toTitle(advisor.last_name) }}
                              </h5>
                              <p class="sub-title">
                                Other accolades and accomplishments
                                (self-reported)
                              </p>
                            </v-col>
                          </v-row> -->

                          <v-row class="accolades">
                            <v-col>
                              <v-img
                                v-for="icon in advisor.icons"
                                :title="icon.title"
                                :key="icon.id"
                                class="advisor-icons"
                                height="70"
                                max-width="250"
                                :src="`https://sh-iwi.sfo2.cdn.digitaloceanspaces.com/icons/${icon.file_uuid}`"
                                contain
                              ></v-img>
                            </v-col>
                          </v-row>
                        </template>

                        <template
                          v-if="advisor.photos.length || advisor.videos.length"
                        >
                          <v-row>
                            <v-col><v-divider></v-divider></v-col>
                          </v-row>

                          <v-row class="photos-videos">
                            <v-col>
                              <h5>
                                See {{ preferredName(advisor) }}'s photos
                                and videos
                              </h5>
                            </v-col>
                          </v-row>

                          <v-row class="photos-videos">
                            <v-col>
                              <figure
                                v-for="photo in advisor.photos"
                                :key="photo.id"
                              >
                                <v-img
                                  :title="photo.caption"
                                  class="advisor-photos"
                                  :src="`https://sh-iwi.sfo2.cdn.digitaloceanspaces.com/photos/${photo.file_uuid}`"
                                ></v-img>
                                <figcaption v-text="photo.caption"></figcaption>
                              </figure>
                              <figure
                                v-for="video in advisor.videos"
                                :key="video.id"
                              >
                                <a :href="video.link" target="new">
                                  <v-img
                                    :contain="!getVideoThumb(video)"
                                    :ref="video.id"
                                    :title="video.caption"
                                    class="advisor-photos media-general"
                                    :class="{
                                      'play-button-overlay':
                                        getVideoThumb(video),
                                    }"
                                    :src="
                                      getVideoThumb(video) ||
                                      '/img/icons/icon-media-general.svg'
                                    "
                                  ></v-img>
                                </a>
                                <figcaption v-text="video.caption"></figcaption>
                              </figure>
                            </v-col>
                          </v-row>
                        </template>
                      </template>
                      <v-row>
                        <v-col><v-divider></v-divider></v-col>
                      </v-row>

                      <v-row>
                        <v-col class="contact-advisor" cols="12" sm="4" md="3">
                          <h5 v-if="advisor.detail_only">Contact Details</h5>
                          <h5 v-else>Contact the advisor</h5>
                          <p>
                            {{ advisor.org_name }}
                            <br v-if="advisor.org_name" />
                            {{ advisor.address_1 }}
                            <br v-if="advisor.address_1" />
                            {{ advisor.address_2 }}
                            <br v-if="advisor.address_2" />
                            {{ advisor.address_3 }}
                            <br v-if="advisor.address_3" />
                            {{ advisor.city }}, {{ advisor.state }}
                            {{ advisor.postal_code }}
                            <br />
                            <a
                              v-if="advisor.email"
                              :href="`mailto:${advisor.email}`"
                              v-text="advisor.email"
                            ></a>
                            <br v-if="advisor.email" />
                            <a
                              v-if="advisor.website"
                              :href="`${advisor.website}`"
                              v-text="advisor.website"
                            ></a>
                            <br v-if="advisor.website" />
                            <a
                              v-if="advisor.phone"
                              :href="`tel:${advisor.phone}`"
                              v-text="advisor.phone"
                            ></a>
                            <br />
                            <template
                              v-for="social in [
                                'linkedin',
                                'facebook',
                                'twitter',
                                'youtube',
                              ]"
                            >
                              <a
                                v-if="advisor[`sm_${social}`]"
                                :href="fixUrl(advisor[`sm_${social}`])"
                                :key="social"
                                target="_blank"
                              >
                                <svg-img
                                  class="social"
                                  :svgUrl="`/img/social/${social}.svg`"
                                  :svgTitle="toTitle(social)"
                                ></svg-img>
                              </a>
                            </template>
                          </p>
                        </v-col>
                        <v-col cols="12" sm="8" md="9">
                          <v-card>
                            <template
                              v-if="hasContactedAdvisor(advisor.cst_key)"
                            >
                              <v-card-title>
                                Thank you for reaching out!
                              </v-card-title>
                            </template>
                            <template
                              v-else-if="
                                previouslyContactedAdvisor(advisor.cst_key)
                              "
                            >
                              <v-card-title>
                                You contacted this advisor on
                                {{
                                  $date(
                                    user.advisors[advisor.cst_key].message_sent
                                  ).format('MM/DD/YYYY')
                                }}
                              </v-card-title>
                            </template>
                            <template v-else>
                              <v-card-title>
                                I would like to contact this advisor
                              </v-card-title>
                              <v-card-text>
                                <v-form v-model="formValid">
                                  <v-row>
                                    <v-col>
                                      <v-text-field
                                        label="Your first Name"
                                        v-model="user.first_name"
                                        :rules="RULES.required"
                                        outlined
                                        hide-details
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col>
                                      <v-text-field
                                        label="Your last Name"
                                        v-model="user.last_name"
                                        :rules="RULES.required"
                                        outlined
                                        hide-details
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col>
                                      <v-text-field
                                        label="Phone Number"
                                        v-model="user.phone"
                                        placeholder="XXX-XXX-XXXX"
                                        :rules="RULES.phone"
                                        outlined
                                        type="tel"
                                        hide-details
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col>
                                      <v-text-field
                                        label="Email Address"
                                        v-model="user.email"
                                        :rules="RULES.email"
                                        outlined
                                        type="email"
                                        hide-details
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col class="contact">
                                      <h5>Contact Preference</h5>
                                    </v-col>
                                  </v-row>

                                  <v-row class="contact">
                                    <v-col>
                                      <v-checkbox
                                        v-model="contact_preference"
                                        label="Email"
                                        value="email"
                                        hide-details
                                        :value-comparator="simpleMatch"
                                        dense
                                      ></v-checkbox>
                                    </v-col>
                                    <v-col>
                                      <v-checkbox
                                        v-model="contact_preference"
                                        label="Phone"
                                        value="phone"
                                        hide-details
                                        :value-comparator="simpleMatch"
                                        dense
                                      ></v-checkbox>
                                    </v-col>
                                    <v-col>
                                      <v-checkbox
                                        v-model="contact_preference"
                                        label="Video"
                                        value="video"
                                        hide-details
                                        :value-comparator="simpleMatch"
                                        dense
                                      ></v-checkbox>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12">
                                      <v-textarea
                                        label="Anything the advisor should know?"
                                        v-model="message"
                                        outlined
                                      ></v-textarea>
                                    </v-col>
                                  </v-row>
                                  <v-row class="contact-terms">
                                    <v-col>
                                      <v-checkbox
                                        v-if="!advisor.detail_only"
                                        v-model="share_criteria"
                                        label="Share my matching criteria with the advisor to help inform conversation"
                                        :false-value="false"
                                        :true-value="true"
                                        hide-details
                                      ></v-checkbox>

                                      <v-checkbox
                                        v-model="user.iwi_can_contact"
                                        label="IWI and associated partners can contact me with marketing materials"
                                        :false-value="false"
                                        :true-value="true"
                                        hide-details
                                      ></v-checkbox>

                                      <v-checkbox
                                        v-model="user.agreed_to_terms"
                                        label="I agree to the terms of use"
                                        :false-value="false"
                                        :true-value="true"
                                        hide-details
                                      ></v-checkbox>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col class="d-flex justify-center">
                                      <v-btn
                                        :disabled="
                                          !formValid ||
                                          checkFormSubmitted(advisor)
                                        "
                                        class="highlight send"
                                        @click="submit(advisor)"
                                      >
                                        SEND
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-form>
                              </v-card-text>
                            </template>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-col>
        </v-row>
        <v-row
          justify="center"
          class="small-screen"
          v-if="!advisor.detail_only && !isSearch && isSeeking(advisor)"
        >
          <v-col>
            <v-expansion-panel-header
              expand-icon="mdi-plus-circle"
              :disable-icon-rotate="false"
              class="more-info"
            >
              <h6 class="more">More Info</h6>
              <h6 class="less">Less Info</h6>
              <template v-slot:actions>
                <v-icon color="#F8D543" light>mdi-plus-circle</v-icon>
              </template>
            </v-expansion-panel-header>
          </v-col>
        </v-row>
      </v-container>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
  // import HelpTip from '@/components/HelpTip'
  import axios from 'axios'

  export default {
    name: 'AdvisorCard',
    components: {},
    props: {
      advisors: Array,
      panel: Array,
    },
    data() {
      return {
        contact_preference: [],
        message: '',
        share_criteria: false,
        formValid: false,
        advisorsContacted: [],
        formSubmitted: [],
      }
    },
    computed: {
      isSearch() {
        return this.$route.query.search || false
      },
      matchFilterTypes() {
        let matchFilterTypes = []

        if (this.calculated.matchFilter.length) {
          matchFilterTypes = this.calculated.matchFilter.map((id) => {
            return this.data.attribLookup[id]
            // let attrib = this.data.attribLookup[id]
            // if (!matchFilterTypes.includes(attrib)) return attrib
          })
          return matchFilterTypes
        } else return []
      },
    },
    methods: {
      // this is not the actual nearby score but is reduces to work properly for the visual representation
      visualNearbyScore(advisor) {
        let score = advisor.scores.nearby / this.nearbyScore
        score = score < 0 ? 1 : score
        score = score * 100 || 0
        return score
      },
      visualPreferencesScore(advisor) {
        const nearbyEnabled =
          this.calculated.matchFilter.includes('00000000000')

        if (nearbyEnabled)
          return (
            (advisor.scores.preferredAttributes / this.nearbyScore) *
            this.visualNearbyScore(advisor)
          )
        else return advisor.scores.preferredAttributes * 100
      },
      hasSelected(attrib) {
        return this.matchFilterTypes.includes(attrib)
      },
      matchingLifeStage(advisor) {
        const lifeStageId = this.user.profile.lifeStageId
        // if lifeStageId is set and match is not already in array, add id to matchIds array
        if (lifeStageId && advisor.scores.lifeStage == 1) {
          if (
            this.calculated.matchFilter.includes(lifeStageId) &&
            !advisor.matchIds.includes(lifeStageId)
          ) {
            advisor.matchIds.push(lifeStageId)
          }
          return true
        } else return false
      },
      matchingHighlight(advisor, highlight) {
        let hasCIMA = advisor.cima_status == 'Certified'
        // let hasCFP = advisor.cfp_status == 'Certified'
        let hasCPWA = advisor.cpwa_status == 'Certified'
        let hasRMA = advisor.rma_status == 'Certified'

        // if advisor detail or search show all advisor highlights OR show any matching to investor
        if (
          advisor.detail_only ||
          this.isSearch ||
          this.calculated.matchFilter.includes(highlight.id)
        ) {
          if (
            (hasCIMA && highlight.cimaScore > highlight.thresholdScore) ||
            // (hasCFP && highlight.cfpScore > highlight.thresholdScore) ||
            (hasCPWA && highlight.cpwaScore > highlight.thresholdScore) ||
            (hasRMA && highlight.rmaScore > highlight.thresholdScore)
          ) {
            // if match is not already in array, add id to matchIds array
            if (
              !advisor.detail_only &&
              !this.isSearch &&
              this.calculated.matchFilter.includes(highlight.id) &&
              !advisor.matchIds.includes(highlight.id)
            )
              advisor.matchIds.push(highlight.id)

            return true
          } else return false
        } else return false
      },
      matchingAttribute(advisor, attrib) {
        // if this is the advisor detail view or search, then just return matching advisor attributes

        if (
          advisor.detail_only ||
          this.isSearch ||
          this.calculated.matchFilter.includes(attrib.id)
        ) {
          if (
            (attrib.id == '00000000000' && advisor.scores.nearby > 0) ||
            advisor.race_ethnicity == attrib.netforumValues ||
            (advisor.gender_identity &&
              attrib.netforumValues.indexOf(advisor.gender_identity) > -1) ||
            (advisor.conduct_virtual_advising &&
              attrib.netforumValues.indexOf(advisor.conduct_virtual_advising) >
                -1)
          ) {
            // if match is not already in array, add id to matchIds array
            if (
              !advisor.detail_only &&
              !this.isSearch &&
              this.calculated.matchFilter.includes(attrib.id) &&
              !advisor.matchIds.includes(attrib.id)
            )
              advisor.matchIds.push(attrib.id)

            return true
          } else return false
        }
      },
      galleryCount(advisor) {
        if (advisor.photos) return advisor.photos.length + advisor.videos.length
        else return 0
      },
      getProfileImage(cst_key) {
        const profileImage = `/napi/advisor-profile-image?cst_key=${cst_key}`

        axios({
          method: 'get',
          url: profileImage,
        })
          .then((response) => {
            return profileUrl
          })

          .catch((error) => {
            return placeHolderUrl
          })
      },
      advisorHeader(advisor) {
        let certs = []
        let listTitle = ''
        if (advisor.cima_status == 'Certified') certs.push('CIMA®')
        if (advisor.cpwa_status == 'Certified') certs.push('CPWA®')
        if (advisor.rma_status == 'Certified') certs.push('RMA®')

        if (certs.length > 0) {
          listTitle = '<span class="big-screen">, </span>' + certs.join(', ')
        }

        return listTitle
      },
      hasContactedAdvisor(advisor_cst_key) {
        return this.advisorsContacted.includes(advisor_cst_key)
      },
      previouslyContactedAdvisor(advisor_cst_key) {
        const u2a = this.user.advisors[advisor_cst_key]
        return u2a && u2a.message_sent
      },
      checkFormSubmitted(advisor) {
        return this.formSubmitted.includes(advisor.cst_key)
      },
      submit(advisor) {
        if (this.formValid) {
          this.formSubmitted.push(advisor.cst_key)
          this.serverError = false

          // If advisor detail view, set selected advisor to advisor being viewed
          if (this.advisors.length > 0 && this.advisors[0].detail_only) {
            this.appState.selected_advisor = this.advisors[0]
          }

          const data = {
            investor_cst_key: this.user.cst_key,
            advisor: {
              cst_key: advisor.cst_key,
              first_name: this.toTitle(advisor.first_name),
              last_name: this.toTitle(advisor.last_name),
              email: advisor.email,
            },
            first_name: this.user.first_name,
            last_name: this.user.last_name,
            phone: this.user.phone,
            email: this.user.email,
            contact_preference: this.contact_preference,
            message: this.message,
            shared_profile: this.share_criteria
              ? this.aboutMe.map(
                  (obj) =>
                    `<strong>${this.toTitle(
                      obj.type
                    )}</strong>: ${encodeURIComponent(obj.title)}`
                )
              : ['User did not share profile'],
          }
          // update user marketing contact preferences and agree to terms
          this.$store.dispatch('updateInvestorProfile')

          this.$store
            .dispatch('contactAdvisor', data)

            .then((response) => {
              if (response.data.success) {
                this.advisorsContacted.push(advisor.cst_key)
              } else {
                this.serverError = true
              }
            })
            .catch((error) => {
              console.log('err', error)
            })
        }
      },

      getVideoThumb(video) {
        // https://www.labnol.org/code/19797-regex-youtube-id
        // Check if Youtube video...
        let regExp =
          /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/
        let match = video.link.match(regExp)
        if (match && match[7].length == 11) {
          return `https://img.youtube.com/vi/${match[7]}/0.jpg`
        }
        // Check if Vimeo video...
        regExp =
          /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i
        match = video.link.match(regExp)
        if (match && match[0].length > 0) {
          axios({
            method: 'get',
            url: `https://vimeo.com/api/oembed.json?url=https%3A//${match[0]}&width=480&height=360`,
          })
            .then((response) => {
              if (response.data) {
                return response.data.thumbnail_url
              }
            })
            .catch((error) => {
              console.log('err', error)
            })
        }

        return false
      },
      hasCert(advisor) {
        return advisor.cima_status == 'Certified' ||
          advisor.cpwa_status == 'Certified' ||
          advisor.rma_status == 'Certified'
          ? true
          : false
      },
      isSeeking(advisor) {
        let seeking = advisor.seeking_new_clients != 'Not Seeking' ? true : false
        //console.log('seeking', seeking)
        return seeking
      }
    },

    created() {},
    updated() {},
    mounted() {
      //console.log(this.advisors)
    },
  }
</script>

<style lang="less">
  @import (reference) '~@/less/variables.less';

  .small-screen {
    &.advisor-intro {
      display: none;
    }
    .v-expansion-panel-header {
      justify-content: center;
      &.more-info {
        margin-top: -1.5em;
        width: 100%;
        .v-expansion-panel-header__icon {
          margin-left: 0;
        }
        .more,
        .less {
          text-align: center;
          max-width: 71px;
          text-transform: uppercase;
        }
      }
    }
  }
  .v-expansion-panels .v-expansion-panel {
    border-radius: 4px;
    margin-top: 1.25em;

    .more {
      display: block;
    }

    .less {
      display: none;
    }

    &.v-expansion-panel--active {
      .more-info {
        .v-icon {
          transform: rotate(135deg);
        }

        .more {
          display: none;
        }

        .less {
          display: block;
        }
      }
    }

    .v-badge {
      display: block;
    }

    .v-badge__wrapper {
      .v-badge__badge {
        inset: initial !important;
        left: -0.6em !important;
        bottom: 1em !important;
        color: @brand-dark-blue;
        background-color: @brand-yellow !important;
        font-family: @font-family-serif;
        font-size: @font-size-base;
        height: 1.6667em;
        width: 1.6667em;
        border-radius: 50%;
        font-weight: 700;
      }
    }

    .v-expansion-panel-header {
      h6 {
        font-family: @font-family-base;
      }
      i.mdi-plus-circle {
        font-size: 2em;
        background-color: black;
        background: radial-gradient(
          circle,
          rgba(0, 0, 0, 1) 40%,
          rgba(0, 0, 0, 0) 40%
        );
      }
    }

    .advisor-card {
      padding: 0;
      .row {
        padding: 6px;
      }
      hr {
        border-bottom: dashed #39607a 1px;
        border-width: 0 0 1px 0;
      }

      .trim-top-padding {
        padding-top: 0;

        .advisor-expansion {
          padding: 0;

          .container {
            margin: 0;
            padding: 0;
            .row:has(.certified) {
              flex-direction: column;
              .col-3 {
                max-width: 55%;
              }
            }
          }
        }
      }

      .trim-bottom-padding {
        padding-bottom: 0;
      }

      .favorite-button {
        justify-content: center;
      }

      .v-btn-toggle.favorite-button {
        border-radius: 2em;

        .v-btn {
          height: 0.5em;
          max-width: 0.6em;

          &:before {
            opacity: 0;
          }

          &.v-btn--icon .v-icon {
            color: @heart;
          }

          &.v-item--active {
            &.v-btn--icon .v-icon {
              color: @heart;
            }
          }
        }
      }

      .advisor-photos {
        border-radius: 4px;
        background-color: @brand-light-blue;
      }

      .profile-photo {
        border-radius: 4px;
        background-color: @brand-light-blue;
        height: 4em;
        width: 4em;
        border-radius: 5px;

        &&.theme--light.v-icon.placeholder {
          color: red;
          font-size: 140px;
        }

        &.media-general {
          .v-image__image--cover {
            background-size: auto;
          }
        }

        .v-responsive__sizer {
          padding-bottom: 100% !important;
        }

        .v-image__image {
          background-position: center center !important;
          background-size: cover;
        }
      }

      .advisor-name {
        font-family: @font-family-serif;
        font-size: @font-size-base;
        font-weight: 700;
        display: flex;
        align-items: flex-end;

        a {
          text-decoration: none;
          color: @text-color;
        }

        .advisor-titles {
          font-size: inherit;
        }
      }

      .advisor-titles {
        font-size: @font-size-mini;
        font-family: @font-family-serif;
      }

      .member-details {
        font-size: @font-size-tiny;
        padding-left: 1em;
        p {
          font-size: @font-size-tiny;

          &:first-child {
            margin-top: 1em;
          }
        }

        h5 {
          font-size: @font-size-tiny;
          margin-top: 1em;
          font-weight: 700;

          span {
            display: block;
            font-weight: 400;
          }
        }
      }

      .match-details {
        h5 {
          font-family: @font-family-base;
          font-size: @font-size-mini;
          font-weight: 400;
          margin-bottom: 0.75em;
        }
        h6 {
          font-family: @font-family-base;
          font-size: @font-size-tiny;
          font-weight: 400;
          margin: 0.75em 0 0.5em;
        }

        .match-detail-wrapper {
          width: 100%;
          border-radius: 3px;
          height: 1.5em;
          background-color: @brand-light-blue;
          box-shadow: inset 0 1px 5px 0 #b0c3cf;

          .match-detail-bar {
            border-radius: 3px;
            height: 1.5em;
            background: linear-gradient(
              90deg,
              @brand-white 0%,
              @brand-mid-blue 100%
            );
            opacity: 0.8;
          }
        }
      }

      .advisor-credential {
        margin: 0;
      }

      .advisor-firm-location {
        margin-bottom: 0.5em;
        span {
          font-weight: 700;
        }
      }

      .accolades,
      .all-services,
      .more-about,
      .photos-videos,
      .contact-advisor,
      .key-matches {
        h5 {
          font-weight: 700;
        }

        .sub-title {
          font-size: @font-size-tiny;
        }
      }

      .key-matches {
        ul,
        li {
          list-style-type: none;
          margin: 0;
          padding: 0 1em 1em 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          line-height: 1em;

          // TODO; Double check this didn't affect any other list item
          // &:last-child,
          // &:nth-last-child(2),
          // &:nth-last-child(3) {
          //   padding-bottom: 0;
          // }

          .v-image {
            margin-right: 0.5em;
          }
        }

        ul {
          align-items: flex-start;
          flex-direction: column;

          &:has(li:nth-child(5)) {
            flex-direction: row;
            flex-wrap: wrap;
          }
        }

        .mismatch {
          text-decoration: line-through;
        }
      }

      .all-services {
        h5 {
          margin-bottom: 1em;
        }

        ul {
          align-items: flex-start;
          flex-direction: column;

          &:has(li:nth-child(5)) {
            flex-direction: row;
            flex-wrap: wrap;
          }

          li {
            line-height: 1.2em;
            padding-right: 1em;
            padding-bottom: 0.75em;
          }
        }

        &.certified.rightBorder {
          border-right: solid @brand-dark-blue 0px;
          border-radius: 0;
          margin-right: 2em;
        }
      }

      .key-matches,
      .all-services {
        ul {
          li {
            flex-basis: 100%;
          }
        }
      }

      .accolades {
        .v-image {
          display: inline-block;
        }

        .v-image__image {
          background-position: left center !important;
        }
      }

      .photos-videos {
        .col {
          display: grid;
          grid-template: auto / repeat(2, 1fr);
          gap: 1.875em;

          h5 {
            grid-column: span 2;
          }

          figure {
            grid-column: span 1;
            max-width: 100%;

            .v-image {
              width: 100%;

              .v-responsive__sizer {
                padding-bottom: 100% !important;
              }

              .v-responsive__content {
                display: none;
              }
            }

            iframe {
              max-width: 100%;
            }

            figcaption {
              font-size: @font-size-tiny;
              padding-top: 0.5em;
            }
          }
        }
      }

      .contact-advisor {
        p {
          font-size: @font-size-tiny;
        }
      }

      .v-card__title {
        background-color: @brand-blue-gray;
        color: @brand-white;
        font-family: @font-family-serif;
        font-size: @font-size-base;
        word-break: normal;
      }

      .v-card__text {
        background-color: @brand-light-blue;
        padding-top: 2em;

        input,
        textarea,
        fieldset {
          background-color: white;
        }
      }

      .contact {
        &.col {
          padding-bottom: 0;
        }

        &.row {
          margin-top: 0;
        }
      }

      .contact-terms {
        &.row {
          margin-top: -2em;

          .col {
            padding-top: 0;

            label {
              font-size: @font-size-tiny;
            }
          }
        }
      }

      .send {
        padding: 0.5em 2em !important;
      }

      .aum-discipline {
        h5,
        p {
          font-size: @font-size-tiny;
        }
      }

      .big-screen {
        display: none;
      }

      .name-fields {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        .advisor-name {
          order: 1;
        }

        .advisor-titles {
          order: 3;
          width: 100%;
        }
        // mobile
        .favorite-button {
          top: -0.4em;
        }

        .small-screen-location {
          order: 4;
          width: 100%;
        }
      }
      .no-wrap {
        flex-wrap: nowrap;
      }

      .play-button-overlay > div.v-image__image.v-image__image--cover:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url('../assets/svg/play-button-overlay.svg');
        background-position: center center;
      }

      .social {
        margin: 1em 0.75em -0.75em 0;
        width: 30px;
        fill: @brand-blue-gray;
      }

      @media screen and (min-width: 600px) {
        padding: 1em;
        .row {
          padding: unset;
        }

        .row:has(.certified) {
          flex-direction: unset !important;
          // Resetting for desktop
          .col-3 {
            max-width: 25%;
          }
        }

        .big-screen {
          display: block;

          &.v-expansion-panel-header {
            display: flex !important;
            justify-content: flex-end;
          }

          h6 {
            text-transform: uppercase;
          }
        }

        .advisor-container {
          padding-top: 0;
          padding-left: 0;

          .more-info-container {
            padding: 0;

            .more-info {
              padding: 0.5em;
            }
          }
        }

        .advisor-name {
          .advisor-titles {
            &.big-screen {
              display: inline;
            }
          }
        }

        .small-screen {
          display: none;
        }

        .profile-photo {
          height: 5em;
          width: 5em;
        }

        .advisor-titlebar {
          border-bottom: dashed @brand-blue-gray 1px;

          .more-info {
            width: auto;
            margin-left: auto;

            h6 {
              margin-right: 0.5em;
              font-weight: 400;
            }
          }
        }

        .key-matches,
        .all-services {
          ul {
            display: flex;
            flex-wrap: wrap;

            li {
              flex-basis: 50%;
            }
          }
          &.certified.rightBorder {
            border-right: solid @brand-dark-blue 0.5px;
          }
        }

        .photos-videos {
          .col {
            display: grid;
            grid-template: auto / repeat(3, 1fr);

            h5 {
              grid-column: span 3;
            }
          }
        }

        .name-fields {
          flex-direction: row;

          .advisor-name {
            order: 1;
          }

          .advisor-titles {
            order: 2;
            width: auto;

            .big-screen {
              display: inline;
            }
          }

          .favorite-button {
            order: 2;
            top: 0;
            margin: auto 0 auto;
          }

          .small-screen-location {
            display: none;
          }
        }
      }

      @media screen and (min-width: 960px) {
        padding: 1.5em;

        .advisor-name {
          font-size: @font-size-uphalf;
        }

        .photos-videos {
          .col {
            display: grid;
            grid-template: auto / repeat(4, 1fr);

            h5 {
              grid-column: span 4;
            }
          }
        }
      }

      @media screen and (min-width: 1264px) {
        .key-matches,
        .all-services {
          ul {
            display: flex;
            flex-wrap: wrap;

            li {
              flex-basis: 33.3%;
            }
          }
        }
      }
    }
  }

  .v-expansion-panel-content__wrap {
    padding: 0;
  }
</style>
