<template>
  <v-app v-if="$route.name == 'AdvisorProfile' || $route.name == 'NotFound'">
    <router-view />
  </v-app>
  <v-app v-else :style="`background-image: url('${bkgSrc}');`">
    <template v-if="dataLoading">
      <v-progress-circular
        class="main-loading"
        indeterminate
        color="#f8d543"
        size="100"
        width="14"
      >
        <span>InvestmentHelp.org</span>
      </v-progress-circular>
    </template>
    <template v-else>
      <app-header></app-header>

      <v-main>
        <router-view />
        <p class="disclaimer" v-if="!appState.minWrap">
          We will not ask you for your email or phone number to see your
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span class="tooltip" v-bind="attrs" v-on="on">
                certified advisor matches.
              </span>
            </template>
            <span>
              The Investments &amp; Wealth Institute is the certifying body for
              professionals who have earned the Institute's CIMA&reg;, CPWA&reg; and
              RMA&reg; certifications. InvestmentHelp.org, managed by the Institute,
              is designed to provide the public with a directory of individuals
              who have earned the CIMA&reg;, CPWA&reg; and RMA&reg; certifications.
              InvestmentHelp.org listings provide information that is
              self-reported by participants. The Investments &amp; Wealth Institute
              does NOT independently verify such self-reported information.
              Investors should do their own due diligence before hiring a
              financial advisor. Investments &amp; Wealth Institute does not endorse
              or recommend financial advisors. Information provided through
              InvestmentHelp.org is for informational purposes only and is not,
              in any way, to be considered investment advice, nor should it be
              construed as a recommendation of any investment product(s), or
              endorsement(s) of any financial advisor, member or certificant.
              Before hiring a professional, regulators suggest you search the
              professional's regulatory record on FINRA's or the SEC's website.
            </span>
          </v-tooltip>
        </p>
      </v-main>
      <certifications></certifications>
      <resources
        :filterable="this.$route.name == 'Resources' ? true : false"
      ></resources>
      <inspiration></inspiration>
      <app-footer></app-footer>
    </template>
  </v-app>
</template>

<script>
  // VIEWS
  import InvestorLifeStage from '@/views/InvestorLifeStage'
  import InvestorFinances from '@/views/InvestorFinances'
  import InvestorPreferences from '@/views/InvestorPreferences'
  import InvestorSituation from '@/views/InvestorSituation'
  import InvestorPriorities from '@/views/InvestorPriorities'
  import InvestorMatches from '@/views/InvestorMatches'
  import AdvisorProfile from '@/views/AdvisorProfile'

  // COMPONENTS
  import AppHeader from '@/components/AppHeader'
  import Certifications from '@/components/Certifications'
  import Resources from '@/components/Resources'
  import Inspiration from '@/components/Inspiration'
  import AppFooter from '@/components/AppFooter'

  import { mapState } from 'vuex'

  export default {
    name: 'App',
    views: {
      InvestorLifeStage,
      InvestorFinances,
      InvestorPreferences,
      InvestorSituation,
      InvestorPriorities,
      InvestorMatches,
      AdvisorProfile,
    },
    components: {
      AppHeader,
      Certifications,
      Resources,
      Inspiration,
      AppFooter,
    },

    data() {
      return {
        siteTitlePrefix: 'IWI Investment Help: ',
        bkgChanging: false,
        bkgSrc: 'https://iwi.superhumane.net/life-stage/ls-growing-4.jpeg',
      }
    },

    computed: {
      currentRouteName() {
        return this.$route.name
      },
      ...mapState({
        data: state => state.data
      }),
    },
    methods: {
      setMinWrap(to) {
        this.appState.minWrap = to == 'AdvisorDetail' ? true : false
      },
      setPageTitle(to) {
        if(this.data) {
          var titleSet = false
          if(this.data.resources && this.data.resources[this.$route.params.resourceSlug]) {
            var resource = this.data.resources[this.$route.params.resourceSlug]
            if(resource.seoTitle) {
              document.title = resource.seoTitle
              titleSet = true
            }
          }

          if(!titleSet) {
            if(this.data.settings) {
              document.title = to.meta.title
                ? this.siteTitlePrefix + to.meta.title
                : this.siteTitlePrefix
            }
          }
        }
      },
      setBkgSrc() {
        if (!this.bkgChanging) {
          const url = 'https://sh-iwi.sfo2.digitaloceanspaces.com/life-stage/'

          if (this.aboutMe[0]) {
            const phase = this.aboutMe[0].netforumValues
            const count = { School: 6, Planning: 6, Growing: 7, Retired: 6 }
            const imgNum = Math.floor(Math.random() * (count[phase] - 1)) + 1

            this.bkgSrc = url + `ls-${phase.toLowerCase()}-${imgNum}.jpeg`
          } else this.bkgSrc = url + 'ls-growing-4.jpeg'
        }
      },
    },
    watch: {
      // do things after route changes
      $route(to, from) {
        this.setMinWrap(to)
        this.setPageTitle(to)
        // reset resource limit
        this.resetResourceLimit()
        this.setBkgSrc()
      },
      data(newData) {
        if (newData && newData.settings && newData.settings.seoTitle) {
          this.setPageTitle({ meta: { title: newData.settings.seoTitle } });
        }
      },
      'user.postal_code'() {
        // if user clears out postal code, remove from profile and push update
        if (!this.user.postal_code) {
          this.user.profile.preferredAttributes =
            this.user.profile.preferredAttributes.filter(
              (item) => item != '00000000000'
            )
          this.$store.dispatch('updateInvestorProfile')
        }
      },
    },
    created() {
      if (
        this.$route.name != 'AdvisorProfile' &&
        this.$route.name != 'NotFound'
      ) {
        // dispatch application initialization
        this.$store.dispatch('initApp')
      }
    },
    mounted() {
      this.setPageTitle(this.$route)
    },
    updated() {},
  }
</script>

<style lang="less">
  @import 'less/variables.less';

  #app.v-application {
    line-height: @line-height-base;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: top;

    .v-progress-circular.main-loading {
      position: absolute;
      top: 40vh;
      left: 50vw;
      margin-left: -50px;

      .v-progress-circular__info {
        font-family: @font-family-serif;
        color: @brand-white;
        font-weight: 700;
        z-index: 1;
      }
    }
    .v-application--wrap {
      background-color: @background-dark-blue;
    }
  }

  .v-main {
    position: relative;
    align-items: center;
    line-height: @line-height-base;

    .v-main__wrap {
      h2.container {
        color: @brand-white;
        font-size: @font-size-h1;
        font-weight: normal;
        color: @brand-white;
        margin: 3em auto 0.5em;
      }

      & > p.disclaimer {
        text-align: center;
        color: white;
        font-size: @font-size-micro;
        padding: 1em 0 0.75em;
        font-style: italic;
      }
    }
  }

  .panel {
    // margin: 1em auto;
    /*width: 90%;
    max-width: 80em;*/
    border-radius: 5px;
    box-shadow: 0 3px 24px 7px rgba(14, 14, 14, 0.5);
    background: @brand-white;

    & + .panel {
      margin-bottom: 5em;
    }
  }

  // GLOBAL STYLES
  h1,
  h2,
  h3,
  h6 {
    line-height: 1.2;
    font-family: @font-family-serif;
    color: @text-color;
  }

  h4,
  h5,
  p {
    font-family: @font-family-base;
    font-weight: normal;
    line-height: @line-height-base;
    color: @text-color;
    font-size: @font-size-base;
  }

  h1 {
    font-size: @font-size-h1;
    color: @invert;
  }

  h2 {
    font-size: @font-size-h2;
  }

  h3 {
    font-size: @font-size-h3;
  }

  h4 {
    font-size: @font-size-h4;
    text-transform: uppercase;
    color: @brand-deep-yellow;
    font-weight: bold;
  }

  sh-1 {
    font-size: @font-size-sh1;
    color: @highlight;
  }

  sh-2 {
    font-size: @font-size-sh2;
    font-weight: bold;
  }

  sh-3 {
    display: block;
    font-size: @font-size-sh3;
  }

  hr.v-divider {
    border: dashed @brand-dark-blue;
    border-width: 0 thin 0 0;
  }

  .tooltip {
    text-decoration: underline;
    text-decoration-style: dotted;
    text-underline-offset: 0.25em;
    cursor: pointer;
  }

  // Class for inverting and highlighting
  .invert {
    color: @invert;
  }
  .highlight {
    color: @highlight;
  }

  // Set top padding on any view container to push content below app bar
  .below-header {
    padding-top: 10em;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: white;
    }

    h2 {
      font-size: @font-size-upthird;
      font-weight: 400;
    }
    .subtitle,
    .directions {
      color: @brand-yellow;
    }

    .subtitle {
      margin: 1.5em 0;
      text-transform: uppercase;
      font-size: @font-size-base;
    }

    .directions {
      display: none;
    }

    .question {
      font-size: @font-size-mini;
      font-family: @font-family-base;
      margin: 1rem 0;
    }
  }

  // VUETIFY COMPONENT STYLES

  // Base IWI Button. Do not apply to app bar or toggle button/children
  .v-btn:not(.app-bar):not(.toggle):not(.v-btn--icon):not(.v-btn-toggle
      > .v-btn):not(.v-btn--text) {
    font-family: @font-family-serif;
    font-weight: bold;
    justify-content: center;
    &.v-size--default {
      font-size: @font-size-base;
    }

    &.theme--light.v-btn {
      color: @brand-dark-blue;
      border-radius: 2em;
      border: thin solid @brand-gold;
      box-shadow: 0px 2px 15px rgba(168, 168, 168, 0.5);

      &:not(.v-btn--active) {
        background-color: white;
        padding: 2em;
      }

      &.highlight:not(.v-btn--active) {
        background-color: @highlight;
        padding: 1.5em 0;
      }
    }
  }

  // Base Item Group
  .v-item-group.theme--light.v-btn-toggle:not(.v-btn-toggle--group) {
    background: none;
  }

  // Divider
  .v-divider.dashed {
    background-repeat: repeat;
    border-color: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg class='svg' xmlns='http://www.w3.org/2000/svg' width='1' height='16' fill='none'><path stroke='%23243746' stroke-dasharray='2 6' stroke-linecap='square' stroke-width='.5' d='M.5 1.012V14.99'/></svg>");

    &.light {
      background-image: url("data:image/svg+xml;utf8,<svg class='svg' xmlns='http://www.w3.org/2000/svg' width='1' height='16' fill='none'><path stroke='%23FFF' stroke-dasharray='2 6' stroke-linecap='square' stroke-width='.5' d='M.5 1.012V14.99'/></svg>");
    }
  }

  .v-item-group.toggle-select .v-btn:not(:first-child) {
    border-left: dashed @brand-dark-blue 1px !important;
  }

  .v-tooltip__content {
    max-width: 40em;
    background: rgba(97, 97, 97, 1);
  }

  // Transparent overlay for header and main body image
  // .background-overlay {
  //   // z-index: -1;
  //   background-color: @background-dark-blue;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  // }
  @media screen and (min-width: 600px) {
    .v-main {
      .v-main__wrap {
        & > p.disclaimer {
          font-size: @font-size-mini;
          padding: 3em 0 2em;
        }
      }
    }

    .below-header {
      padding-top: 11em;

      h2 {
        font-size: @font-size-h1;
        margin-bottom: 1em;
      }

      h3.question {
        font-size: @font-size-upthird;
      }

      .subtitle {
        font-size: @font-size-upthird;
      }

      .directions {
        display: block;
        font-size: @font-size-mini;
        padding-top: 0.5em;
      }

      .question {
        font-size: @font-size-base;
        margin-bottom: 1rem;
      }
    }
  }
  @media screen and (max-width: 599px) {
    .below-header {
      padding-top: 8em;
    }
  }
</style>
